import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Segment, Icon, Modal, Menu, Pagination, Dropdown, Input } from 'semantic-ui-react';
import { formatDate, findErrorMessage } from 'helpers';
import AddCoupon from './AddCoupon';
import CouponTable from './CouponTable';
import './Coupons.css';

const options = [
  { key: 1, text: '25', value: 25 },
  { key: 2, text: '50', value: 50 },
  { key: 3, text: '100', value: 100 },
];

const CouponModal = ({
  sortBy,
  sortOrder,
  networkMerchant,
  getCoupons,
  createCoupon,
  updateCoupon,
  setLastUpdated,
  viewOnly,
}) => {
  const [activeDates, setActiveDates] = useState('active');
  const [coupons, setCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showClearButton, setShowClearButton] = useState(false);

  const canEdit = useSelector(state => state.user.canEdit);
  const isViewOnly = viewOnly ?? !canEdit;
  const fetchCoupons = useCallback(
    async filters => {
      try {
        setIsLoading(true);
        const coupons = await getCoupons(networkMerchant.ID, filters);
        setCoupons(coupons);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setErrorMessage(findErrorMessage(error));
      }
    },
    [getCoupons, networkMerchant],
  );

  const handleUpdateCoupon = async coupon => {
    try {
      setIsLoading(true);
      const updatedCoupon = {
        ...coupon,
        StartDate: formatDate(coupon.StartDate),
        EndDate: formatDate(coupon.EndDate),
        Disabled: !coupon.Disabled,
      };
      await updateCoupon(updatedCoupon);
      setLastUpdated();
      setIsLoading(false);
      setCoupons(coupons.map(c => (c.ID === updatedCoupon.ID ? updatedCoupon : c)));
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(findErrorMessage(error));
    }
  };

  const handleFilterClick = (e, { name }) => {
    setActiveDates(name);
    setActivePage(1);
    setTotalPages(1);
    fetchCoupons({
      activePage,
      pageSize,
      sortBy,
      sortOrder,
      activeDates: name,
    });
  };

  const handlePagination = (e, { activePage }) => setActivePage(activePage);
  const handlePageSizeChange = (e, { value }) => {
    setPageSize(value);
    setTotalPages(1);
    setActivePage(1);
    fetchCoupons({
      activePage,
      pageSize: value,
      sortBy,
      sortOrder,
      ...(searchTerm !== '' ? { searchTerm } : { activeDates }),
    });
  };

  const handleChange = e => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = e => {
    // only do work on 'enter'
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setActivePage(1);
    setShowClearButton(true);
    fetchCoupons({
      activePage,
      pageSize,
      searchTerm,
      sortBy,
      sortOrder,
    });
  };

  const handleClear = () => {
    setSearchTerm('');
    setActivePage(1);
    setShowClearButton(false);
    fetchCoupons({
      activePage,
      pageSize,
      activeDates,
      sortBy,
      sortOrder,
    });
  };

  const handleModalOpen = () => {
    fetchCoupons({
      activePage,
      pageSize,
      sortBy,
      sortOrder,
      activeDates,
    });
  };

  useEffect(() => {
    // Adds to the totalPage number if we have 1 more coupon than the given page size and if the current page number is equal to the total page number (prevents the totalPage from increasing if we toggle back and forth between middle pages which would incorrectly add to the totalPage number)
    if (coupons.length > pageSize && activePage === totalPages) {
      setTotalPages(totalPages => totalPages + 1);
    }
  }, [coupons, activePage, pageSize, totalPages]);

  return (
    <Segment.Group className="coupons">
      <Modal
        className="coupon-modal centered"
        closeIcon={true}
        size="fullscreen"
        trigger={
          <Segment padded className="header" onClick={handleModalOpen}>
            <h3 className="text-center font-montserrat">
              <Icon name="ticket" />
              Coupons
            </h3>
          </Segment>
        }
      >
        <Modal.Header>
          <Icon name="ticket" />
          Coupons
        </Modal.Header>

        <Modal.Content scrolling>
          <div className="filters">
            <Menu secondary>
              <Menu.Item header>Filter By</Menu.Item>
              <Menu.Item
                name="expired"
                active={activeDates === 'expired' && searchTerm === ''}
                onClick={handleFilterClick}
              />
              <Menu.Item
                name="active"
                active={activeDates === 'active' && searchTerm === ''}
                onClick={handleFilterClick}
              />
              <Menu.Item
                name="upcoming"
                active={activeDates === 'upcoming' && searchTerm === ''}
                onClick={handleFilterClick}
              />
            </Menu>
            <div>
              <Icon name="x" color="red" onClick={handleClear} className={!showClearButton ? 'hidden' : null} />
              <Input
                placeholder="Search for a code..."
                onChange={handleChange}
                value={searchTerm}
                onKeyDown={e => handleKeyDown(e)}
              />
            </div>
          </div>
          {errorMessage && <div style={{ color: 'red' }}>Error: {errorMessage}</div>}
          {coupons.length === 0 ? (
            <div className="no-coupons">No coupons with these filters...</div>
          ) : (
            <CouponTable handleUpdateCoupon={handleUpdateCoupon} coupons={coupons} viewOnly={isViewOnly} />
          )}
        </Modal.Content>
        <Modal.Actions>
          {isLoading && <Icon loading name="circle notch" />}
          <Dropdown
            className="page-size"
            text={`${pageSize}`}
            options={options}
            onChange={handlePageSizeChange}
            name="pageSize"
            value={pageSize}
          />
          <Pagination
            onPageChange={handlePagination}
            boundaryRange={0}
            activePage={activePage}
            ellipsisItem={null}
            firstItem={undefined}
            lastItem={undefined}
            siblingRange={0}
            totalPages={totalPages}
          />
        </Modal.Actions>
      </Modal>
      {!isViewOnly && (
        <AddCoupon setLastUpdated={setLastUpdated} createCoupon={createCoupon} nmID={networkMerchant.ID} />
      )}
    </Segment.Group>
  );
};

export default CouponModal;
