import React, { useState } from 'react';
import { Modal, Form, Checkbox, Icon, Button } from 'semantic-ui-react';
import { ICommission } from './types';
interface ICommissionsEditModalProps {
  isOpen: boolean;
  isDQContractor: boolean;
  isViewOnly: boolean;
  commission: ICommission;
  onClose: () => void;
  handleUpdateCommission: (commission: ICommission) => void;
}

const CommissionsEditModal: React.FC<ICommissionsEditModalProps> = ({
  isOpen,
  isDQContractor,
  isViewOnly,
  commission,
  onClose,
  handleUpdateCommission,
}) => {
  const [isDisabled, setIsDisabled] = useState(commission.Disabled);
  const [displayName, setDisplayName] = useState(commission.DisplayName);
  const [isDisplayNameLocked, setIsDisplayNameLocked] = useState(commission.Locks.includes('DisplayName'));

  const hasFormChanged =
    displayName !== commission.DisplayName ||
    isDisabled !== commission.Disabled ||
    isDisplayNameLocked !== commission.Locks.includes('DisplayName');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedCommission = {
      ...commission,
      DisplayName: displayName,
      Disabled: isDisabled,
      Locks: isDisplayNameLocked
        ? [...commission.Locks, 'DisplayName']
        : commission.Locks.filter(lock => lock !== 'DisplayName'),
    };
    handleUpdateCommission(updatedCommission);
    onClose();
  };

  return (
    <Modal className="font-montserrat" closeIcon open={isOpen} onClose={onClose}>
      <Modal.Header className="!font-montserrat">{`Commission (${commission.ID})`}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>ID</label>
              <input disabled className="!font-montserrat" type="text" value={commission.ID} />
            </Form.Field>
            <Form.Field>
              <label>Status</label>
              {isViewOnly ? (
                <Icon name={isDisabled ? 'minus circle' : 'check circle'} />
              ) : (
                <Checkbox
                  disabled={isDQContractor}
                  toggle
                  defaultChecked={!commission.Disabled}
                  onChange={() => setIsDisabled(!isDisabled)}
                />
              )}
            </Form.Field>
            <Form.Field className="">
              <label>Is Default</label>
              <Icon name={commission.IsDefault ? 'check circle' : 'minus circle'} />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Name</label>
            <input disabled className="!font-montserrat" type="text" value={commission.Name} />
          </Form.Field>
          <Form.Field>
            <label>
              <div className="flex flex-nowrap items-center">
                Display Name
                <Icon
                  disabled={isViewOnly}
                  className="!mb-2"
                  data-testid="status-icon"
                  name={isDisplayNameLocked ? 'lock' : 'lock open'}
                  onClick={() => setIsDisplayNameLocked(!isDisplayNameLocked)}
                />
              </div>
            </label>
            <textarea
              disabled={isViewOnly}
              className="!font-montserrat"
              rows={2}
              value={displayName}
              onChange={e => setDisplayName(e.currentTarget.value.trim())}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Kind</label>
              <input disabled className="!font-montserrat" type="text" value={commission.Kind} />
            </Form.Field>
            <Form.Field>
              <label>Amount</label>
              <input disabled className="!font-montserrat" type="text" value={commission.Amount} />
            </Form.Field>
          </Form.Group>
          {!isViewOnly && (
            <div className="flex justify-center">
              <Button type="submit" disabled={!hasFormChanged} className="orange-rounded-button !text-sm">
                Save Changes
              </Button>
            </div>
          )}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default CommissionsEditModal;
