import React, { useEffect, useState } from 'react';
import { Segment, Icon, Accordion } from 'semantic-ui-react';
import CommissionHistoryLog from './CommissionHistoryLog';
import CommissionsTable from './CommissionsTable';
import NetworkMerchantAudit from 'components/NetworkMerchantAudit/NetworkMerchantAudit';

const AUDIT_KIND = 'NETWORK_MERCHANT_COMMISSIONS';

const CommissionsList = ({
  commissions,
  handleUpdateCommission,
  commissionHistory,
  commissionHistoryIsLoading,
  networkMerchant,
  auditIsLoading,
  getNetworkMerchantAudit,
  createNetworkMerchantAudit,
}) => {
  const [networkMerchantAudit, setNetworkMerchantAudit] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const fetchNetworkMerchantAudit = async () => {
    try {
      const networkMerchantAudit = await getNetworkMerchantAudit(AUDIT_KIND);

      if (networkMerchantAudit?.auditKind === AUDIT_KIND) {
        setNetworkMerchantAudit(networkMerchantAudit);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchNetworkMerchantAudit();
  }, []);

  return (
    <Segment.Group className="cursor-pointer">
      <Accordion className="p-5">
        <Accordion.Title active={isVisible} className="header">
          <h3 className="mr-2 font-montserrat" onClick={() => setIsVisible(!isVisible)}>
            <Icon name="dollar" />
            Commissions ({commissions.length})
          </h3>
          {isVisible && (
            <NetworkMerchantAudit
              networkMerchantAudit={networkMerchantAudit}
              isLoading={auditIsLoading}
              createNetworkMerchantAudit={() => {
                createNetworkMerchantAudit(AUDIT_KIND);
                fetchNetworkMerchantAudit();
              }}
            />
          )}
        </Accordion.Title>
        <Accordion.Content active={isVisible}>
          <div className="max-h-[500px] overflow-y-auto">
            <CommissionsTable commissions={commissions} handleUpdateCommission={handleUpdateCommission} />
          </div>
          <CommissionHistoryLog
            commissionHistory={commissionHistory}
            commissions={commissions}
            isLoading={commissionHistoryIsLoading}
            nm={networkMerchant}
          />
        </Accordion.Content>
      </Accordion>
    </Segment.Group>
  );
};

export default CommissionsList;
