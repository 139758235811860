import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Segment, Icon, Accordion, Modal, Button, Form } from 'semantic-ui-react';
import FloatingLoadingOverlay from 'components/FloatingLoadingOverlay/FloatingLoadingOverlay';
import { dqx } from 'helpers/dqx';
import { getUserCanEdit } from 'selectors/user';
import { Keyword } from '../../NetworkMerchantOnboarding/types';
import AddKeyword from '../AddKeyword/AddKeyword';
import KeywordTag from '../KeywordTag/KeywordTag';

import './KeywordList.css';

interface KeywordListProps {
  handleUpsertKeyword: (keyword: Keyword) => void;
  handleRemoveKeyword: (keyword: Keyword) => void;
  isLoading: boolean;
  keywords: Keyword[];
  viewOnly: boolean;
}

const KeywordList = ({ handleUpsertKeyword, handleRemoveKeyword, isLoading, keywords, viewOnly }: KeywordListProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [doesUserHaveDQXInstalled, setUserHaveDQXInstalled] = useState(false);
  const [showDomainsOnly, setShowDomainsOnly] = useState(true);
  const canEdit = useSelector(state => getUserCanEdit(state));
  const isViewOnly = viewOnly ?? !canEdit;
  const toggleIsVisible = () => {
    setIsVisible(prevState => !prevState && !!keywords.length);
  };

  const isLastDomainKeyword = useMemo(() => {
    return keywords.filter(keyword => keyword.Kind === 'domain').length <= 1;
  }, [keywords]);

  const isLastSyncedDomainKeyword = useMemo(() => {
    return keywords.filter(keyword => keyword.Kind === 'domain' && keyword.Synced).length <= 1;
  }, [keywords]);

  // Returns a list of either all keywords or only the domain keywords depending on the state of showDomainsOnly
  const keywordsList = useMemo(() => {
    return showDomainsOnly ? keywords.filter(keyword => keyword.Kind === 'domain') : keywords;
  }, [keywords, showDomainsOnly]);

  const sendActiveDomains = async () => {
    try {
      const response = await dqx.sendActiveDomains({
        domains: keywords
          .filter(keyword => keyword.Kind === 'domain')
          .map(({ KeywordID, KeywordName }) => ({ ID: KeywordID, Domain: KeywordName })),
      });
      if (!response) throw new Error('There was an issue sending active domains to DQX');
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      const isInstalled = await dqx.isInstalled();
      setUserHaveDQXInstalled(isInstalled);
    })();
  }, []);

  return (
    <Segment.Group className="keyword-list">
      <Segment>
        {isLoading && <FloatingLoadingOverlay />}
        <Accordion>
          <Accordion.Title active={isVisible} className="header">
            {doesUserHaveDQXInstalled && (
              <Button className="dqx-button" color="green" onClick={sendActiveDomains}>
                Send to DQX
              </Button>
            )}
            <h3 className="font-montserrat" onClick={toggleIsVisible}>
              <Icon name="tag" />
              Keywords ({keywords.length}
              {keywords.length === 50 && '+'})
            </h3>
            <label className={isVisible ? 'domains-only-toggle' : 'domains-only-toggle visually-hidden'}>
              <span className="domains-only-label">{showDomainsOnly ? 'DOMAIN' : 'ALL'}</span>
              <Form.Checkbox toggle defaultChecked={false} onChange={() => setShowDomainsOnly(!showDomainsOnly)} />
            </label>
            {!isViewOnly && (
              <Modal trigger={<Icon name="add" size="large" className="add-keyword" />}>
                <Modal.Header>
                  <Icon name="tag" />
                  Add Keyword
                </Modal.Header>
                <Modal.Content>
                  <AddKeyword handleAddKeyword={handleUpsertKeyword} />
                </Modal.Content>
              </Modal>
            )}
          </Accordion.Title>
          <Accordion.Content active={isVisible}>
            {keywordsList.map(k => {
              return (
                <KeywordTag
                  key={k.ID}
                  keyword={k}
                  isLastDomainKeyword={isLastDomainKeyword && k.Kind === 'domain'}
                  isLastSyncedDomainKeyword={isLastSyncedDomainKeyword && k.Kind === 'domain'}
                  handleRemoveKeyword={handleRemoveKeyword}
                  handleUpdateKeyword={handleUpsertKeyword}
                  viewOnly={isViewOnly}
                  // only pass if out of sync
                  syncKeyword={k.Synced === false ? handleUpsertKeyword : undefined}
                />
              );
            })}
          </Accordion.Content>
        </Accordion>
      </Segment>
    </Segment.Group>
  );
};

KeywordList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  keywords: PropTypes.array.isRequired,
  handleUpsertKeyword: PropTypes.func.isRequired,
  handleRemoveKeyword: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

export default React.memo(KeywordList);
