import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { Icon, Table } from 'semantic-ui-react';
import { checkIsDQContractor } from 'helpers/auth0';
import CommissionsEditModal from './CommissionsEditModal';

const CommissionsRow = ({ commission, handleUpdateCommission, viewOnly }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const canEdit = useSelector(state => state.user.canEdit);
  const isViewOnly = viewOnly ?? !canEdit;
  const { user } = useAuth0();
  const isDQContractor = checkIsDQContractor(user);

  return (
    <>
      <Table.Row onClick={() => setIsEditModalOpen(true)} className={commission.Disabled ? 'disable' : null}>
        <Table.Cell className="!text-center text-lg">
          <Icon name={commission.Disabled ? 'minus circle' : 'check circle'} />
        </Table.Cell>

        <Table.Cell>{commission.ID}</Table.Cell>
        <Table.Cell className="max-w-[150px] truncate cursor-pointer">{commission.Name}</Table.Cell>
        <Table.Cell>{commission.Kind}</Table.Cell>
        <Table.Cell>{commission.Amount}</Table.Cell>
        <Table.Cell className="max-w-[150px] cursor-pointer">
          <div className="flex flex-nowrap -ml-2">
            <Icon
              disabled={isViewOnly}
              className="!mb-2"
              name={commission.Locks.includes('DisplayName') ? 'lock' : 'lock open'}
            />
            <span className="ml-2 truncate">{commission.DisplayName}</span>
          </div>
        </Table.Cell>
        <Table.Cell>{commission.IsDefault}</Table.Cell>
      </Table.Row>
      {isEditModalOpen && (
        <CommissionsEditModal
          isOpen={isEditModalOpen}
          isDQContractor={isDQContractor}
          isViewOnly={isViewOnly}
          commission={commission}
          onClose={() => setIsEditModalOpen(false)}
          handleUpdateCommission={handleUpdateCommission}
        />
      )}
    </>
  );
};

export default CommissionsRow;
