import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Icon, Loader, Checkbox, Button, Label } from 'semantic-ui-react';
import CategoryModal from '../Category/CategoryModal';
import DisabledLabel from '../DisabledLabel/DisabledLabel';
import HistoryLog from '../HistoryLogV2/HistoryLog';
import ImageList from '../Image/ImageList/ImageList';
import Input from '../Input/Input';
import KeywordList from '../Keyword/KeywordList/KeywordList';
import Lock from '../Lock/Lock';
import NetworkMerchantContainer from '../NetworkMerchant/NetworkMerchantContainer';
import NotFound from '../NotFound/NotFound';
import './Merchant.css';

class Merchant extends Component {
  state = {
    isLoading: true,
    merchantHistory: [],
    merchantHistoryIsLoading: false,
    merchant: {},
    networkMerchantsIsLoading: true,
    networkMerchants: [],
    keywordsIsLoading: true,
    keywords: [],
    categoryOptions: [],
    merchantCategories: [],
    notFound: false,
  };

  getMerchant = async () => {
    const { getMerchant, match } = this.props;
    try {
      const merchant = await getMerchant(match.params.merchantId);
      this.setState({
        merchant,
        isLoading: false,
      });

      this.getMerchantNetworkMerchants();
      this.getMerchantKeywords();
      this.getCategoryOptions();
      this.getMerchantCategories();
      this.getMerchantHistory();
    } catch (e) {
      console.error(e);
      const status = e?.response?.statusCode || e?.response?.status || e?.status;
      if (status === 404) {
        this.setState({ notFound: true });
      } else {
        return Promise.reject(e);
      }
    }
  };

  getMerchantHistory = async () => {
    const { merchant } = this.state;
    const { getMerchantHistory, getMerchantCategoryHistory, getMerchantImageHistory } = this.props;
    this.setState({ merchantHistoryIsLoading: true });
    try {
      const [history, categoryHistory, imageHistory] = await Promise.all([
        getMerchantHistory(merchant.ID),
        getMerchantCategoryHistory(merchant.ID),
        getMerchantImageHistory(merchant.ID),
      ]);
      const sortByTimestamp = (a, b) => {
        const dateA = new Date(a.Timestamp);
        const dateB = new Date(b.Timestamp);
        return dateB - dateA;
      };
      this.setState({
        merchantHistory: [...history, ...categoryHistory, ...imageHistory].sort(sortByTimestamp),
        merchantHistoryIsLoading: false,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateMerchant = async update => {
    const { updateMerchant, match, getMerchant, setLastUpdated } = this.props;
    // merchant.Locks are an array of strings
    // LockLabel will handle the change as long as merchant is expanded here
    // make sure merchant is up to date
    const merchant = await getMerchant(match.params.merchantId);
    await updateMerchant({
      ...merchant,
      ...update,
    });
    this.setState({ categoryIDSelectionChanged: false });
    setLastUpdated();
  };

  getCategoryOptions = async () => {
    const { getCategoryOptions, match } = this.props;
    try {
      const categoryOptions = await getCategoryOptions(match.params.merchantId);

      this.setState({
        categoryOptions,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getMerchantCategories = async () => {
    const { getMerchantCategories, match } = this.props;
    this.setState({ merchantCategoryIDIsLoading: true });
    try {
      const merchantCategories = await getMerchantCategories(match.params.merchantId);
      this.setState({
        merchantCategories: merchantCategories,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateMerchantCategoryIDs = async (e, data) => {
    this.setState({
      categoryIDSelectionChanged: true,
      updatedMerchantCategoryIDs: data.value,
    });
  };

  updateMerchantCategories = async Categories => {
    const { updateMerchantCategories, match } = this.props;

    try {
      const userEmail = localStorage.getItem('userEmail');
      const ModifiedAuthor = userEmail ? userEmail : 'merchant admin category update';
      await updateMerchantCategories(match.params.merchantId, { Categories, ModifiedAuthor });
      this.setState({ merchantCategories: Categories });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getMerchantNetworkMerchants = async () => {
    const { match, getMerchantNetworkMerchants } = this.props;
    const networkMerchants = await getMerchantNetworkMerchants(match.params.merchantId);
    this.setState({
      networkMerchants,
      networkMerchantsIsLoading: false,
    });
  };

  getMerchantKeywords = async () => {
    const { getMerchantKeywords, match } = this.props;
    this.setState({ keywordsIsLoading: true });
    try {
      const keywords = await getMerchantKeywords(match.params.merchantId);
      this.setState({
        keywords,
        keywordsIsLoading: false,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  upsertMerchantKeyword = async keyword => {
    const { upsertMerchantKeyword, match, setLastUpdated } = this.props;
    try {
      await upsertMerchantKeyword(match.params.merchantId, keyword);
      setLastUpdated();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  removeMerchantKeyword = async keyword => {
    const { deleteMerchantKeyword, match, setLastUpdated } = this.props;
    try {
      await deleteMerchantKeyword(match.params.merchantId, keyword);
      setLastUpdated();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  addMerchantImage = async imageDetails => {
    const { addMerchantImage, match, setLastUpdated } = this.props;
    try {
      await addMerchantImage(match.params.merchantId, imageDetails);
      setLastUpdated();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  removeMerchantImage = async merchantImageId => {
    const { deleteMerchantImage, match, setLastUpdated } = this.props;
    try {
      await deleteMerchantImage(match.params.merchantId, merchantImageId);
      setLastUpdated();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  async componentDidMount() {
    this.getMerchant();
  }

  async componentDidUpdate(prevProps) {
    const { match, lastUpdated } = this.props;
    // make sure keywords are up to date
    if (lastUpdated !== prevProps.lastUpdated) {
      this.getMerchant();
    }
    // if route changes and is root resource
    if (match && match.params.merchantId !== prevProps.match.params.merchantId) {
      this.setState({
        isLoading: true,
        notFound: false,
      });
      this.getMerchant();
    }
  }
  render() {
    const {
      isLoading,
      merchantHistory,
      merchantHistoryIsLoading,
      merchant,
      networkMerchantsIsLoading,
      networkMerchants,
      keywordsIsLoading,
      keywords,
      categoryOptions,
      merchantCategories,
      notFound,
    } = this.state;
    const { history, isDQContractor, canEdit } = this.props;

    if (notFound) {
      return <NotFound />;
    }

    if (isLoading) {
      return <Loader active size="massive" inline="centered" />;
    }

    return (
      <Segment.Group>
        <Segment.Group className="merchant bg-white">
          <Segment>
            {!isDQContractor && canEdit ? (
              <DisabledLabel
                isDisabled={merchant.Disabled}
                handleResourceChange={this.updateMerchant}
                locks={merchant.Locks}
                uneditable={isDQContractor}
                titleLabel="Merchant"
              >
                <Lock locks={merchant.Locks} handleResourceChange={this.updateMerchant} name="Disabled" />
              </DisabledLabel>
            ) : (
              <Label color={merchant.Disabled ? 'red' : 'green'} attached="top right">
                <span>{merchant.Disabled ? 'Disabled' : 'Enabled'}</span>
              </Label>
            )}
            <h3>
              <Icon name="cube" />
              Merchant (ID: {merchant.ID})
            </h3>
            <Input label="Name" value={merchant.Name} handleResourceChange={this.updateMerchant} disabled={!canEdit} />
            {!isDQContractor && (
              <div className="checkboxes-column">
                <div className="toggle-checkbox">
                  <Checkbox
                    toggle
                    label={
                      <label>
                        <span className="checkbox-lock-container">
                          <Lock
                            locks={merchant.Locks}
                            name="BrowserExtensionDisabled"
                            handleResourceChange={this.updateMerchant}
                            disabled={!canEdit}
                          />
                          Browser Extension Disabled
                        </span>
                      </label>
                    }
                    checked={merchant.BrowserExtensionDisabled}
                    onChange={() =>
                      this.updateMerchant({
                        BrowserExtensionDisabled: !merchant.BrowserExtensionDisabled,
                        Locks: [...merchant.Locks, 'BrowserExtensionDisabled'],
                      })
                    }
                    disabled={!canEdit}
                  />
                </div>
                <div className="toggle-checkbox">
                  <Checkbox
                    toggle
                    label={
                      <label>
                        <span className="checkbox-lock-container">
                          <Lock
                            locks={merchant.Locks}
                            name="CashbackDisabled"
                            handleResourceChange={this.updateMerchant}
                            disabled={!canEdit}
                          />
                          Cashback Disabled
                        </span>
                      </label>
                    }
                    checked={merchant.CashbackDisabled}
                    onChange={() =>
                      this.updateMerchant({
                        CashbackDisabled: !merchant.CashbackDisabled,
                        Locks: [...merchant.Locks, 'CashbackDisabled'],
                      })
                    }
                    disabled={!canEdit}
                  />
                </div>
                <div className="toggle-checkbox">
                  <Checkbox
                    toggle
                    label={
                      <label>
                        <span className="checkbox-lock-container">
                          <Lock
                            locks={merchant.Locks}
                            name="ShareAndEarnDisabled"
                            handleResourceChange={this.updateMerchant}
                            disabled={!canEdit}
                          />
                          Share And Earn Disabled
                        </span>
                      </label>
                    }
                    checked={merchant.ShareAndEarnDisabled}
                    onChange={() =>
                      this.updateMerchant({
                        ShareAndEarnDisabled: !merchant.ShareAndEarnDisabled,
                        Locks: [...merchant.Locks, 'ShareAndEarnDisabled'],
                      })
                    }
                    disabled={!canEdit}
                  />
                </div>
                <div className="toggle-checkbox">
                  <Checkbox
                    toggle
                    label={
                      <label>
                        <span className="checkbox-lock-container">
                          <Lock
                            locks={merchant.Locks}
                            name="SERPInjectionDisabled"
                            handleResourceChange={this.updateMerchant}
                            disabled={!canEdit}
                          />
                          SERP Injection Disabled
                        </span>
                      </label>
                    }
                    checked={merchant.SERPInjectionDisabled}
                    onChange={() =>
                      this.updateMerchant({
                        SERPInjectionDisabled: !merchant.SERPInjectionDisabled,
                        Locks: [...merchant.Locks, 'SERPInjectionDisabled'],
                      })
                    }
                    disabled={!canEdit}
                  />
                </div>
              </div>
            )}
            <CategoryModal
              merchantID={this.props.match.params.merchantId}
              categoryOptions={categoryOptions}
              merchantCategories={merchantCategories}
              updateMerchantCategories={this.updateMerchantCategories}
              disabled={!canEdit}
            />

            {!isDQContractor && (
              <Button
                as="a"
                target="_blank"
                href={`/sales-dashboard?filter_merchant_id=${merchant.ID}`}
                rel="noreferrer"
              >
                View in Sales Dashboard
              </Button>
            )}
          </Segment>

          <ImageList
            images={merchant.Images}
            addMerchantImage={this.addMerchantImage}
            removeMerchantImage={this.removeMerchantImage}
          />
          {!isDQContractor && (
            <KeywordList
              isLoading={keywordsIsLoading}
              keywords={keywords}
              handleUpsertKeyword={this.upsertMerchantKeyword}
              handleRemoveKeyword={this.removeMerchantKeyword}
            />
          )}

          {merchantHistoryIsLoading ? (
            <Loader active size="massive" inline="centered" />
          ) : (
            <HistoryLog history={merchantHistory} />
          )}
        </Segment.Group>

        {networkMerchantsIsLoading ? (
          <Loader active size="massive" inline="centered" />
        ) : (
          networkMerchants.map(nm => (
            <NetworkMerchantContainer
              key={nm.ID}
              networkMerchant={nm}
              includeKeywords
              history={history}
              isMerchantView
              isDQContractor={isDQContractor}
            />
          ))
        )}
      </Segment.Group>
    );
  }
}

Merchant.propTypes = {
  match: PropTypes.object.isRequired,
  updateMerchant: PropTypes.func.isRequired,
  getMerchant: PropTypes.func.isRequired,
  getMerchantNetworkMerchants: PropTypes.func.isRequired,
  getMerchantKeywords: PropTypes.func.isRequired,
  upsertMerchantKeyword: PropTypes.func.isRequired,
  deleteMerchantKeyword: PropTypes.func.isRequired,
  getCategoryOptions: PropTypes.func.isRequired,
  getMerchantCategories: PropTypes.func.isRequired,
  updateMerchantCategories: PropTypes.func.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  addMerchantImage: PropTypes.func.isRequired,
  deleteMerchantImage: PropTypes.func.isRequired,
  isDQContractor: PropTypes.bool,
  canEdit: PropTypes.bool,
};

export default Merchant;
